// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
const keys = {37: 1, 38: 1, 39: 1, 40: 1};

export const preventDefaultForScrollKeys = (event) => {
  if (keys[event.keyCode]) {
    preventDefault(event);
    return false;
  }
}

export const deactivateScrollbar = () => document.querySelector("html").style.overflowY = 'hidden'
export const reactivateScrollbar = () => document.querySelector("html").style.overflowY = 'auto'
export const preventDefault = (event) => event.preventDefault()

export const addScrollHandlers = (scroll) => {
  if (typeof window !== 'undefined') {
    window.addEventListener(WheelEvent, scroll, { passive: false })
    window.addEventListener('wheel', scroll, { passive: false })
    window.addEventListener('mousewheel', scroll, { passive: false })
    window.addEventListener('touchstart', scroll, { passive: false })
    window.addEventListener('touchend', scroll, { passive: false })
    window.addEventListener('touchmove', preventDefault, { passive: false })
    window.addEventListener('keydown', preventDefaultForScrollKeys, false);
  }
}

export const removeScrollHandlers = (scroll) => {
  if (typeof window !== 'undefined') {
    window.removeEventListener(WheelEvent, scroll)
    window.removeEventListener('wheel', scroll)
    window.removeEventListener('mousewheel', scroll)
    window.removeEventListener('touchstart', scroll)
    window.removeEventListener('touchend', scroll)
    window.removeEventListener('touchmove', preventDefault)
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
  }
}
