import React from "react"
import { useInView } from 'react-intersection-observer';
import { animated, useSpring } from 'react-spring'
import { isMobile } from "utilities/tools"

 const Mid = ({ className, children }) => {
  const [ref, inView,] = useInView({
    triggerOnce: true,
    rootMargin: '100px 0px 0px 0px',
  })

  const mobileSpring = useSpring({
    from: { transform: inView && isMobile() ? 'translateY(130px)' : 'translateY(0)' },
    transform: 'translateY(0)',
    config: {
      mass: 1,
      tension: 402,
      friction: 198
    }
  })

  return <animated.div className={className} style={mobileSpring} ref={ref}>
    {children}
  </animated.div>
}
export default Mid
