import React from "react"
import { connect as connectFela } from "react-fela"
import { animated, useSpring, config } from 'react-spring'
import SanityImage from "gatsby-plugin-sanity-image"

import IntroBlock from 'components/IntroBlock'

export default connectFela((props) => ({
  introBlock: {
    display: 'none',
    tabletPortraitAndUp: {
      display: 'block'
    }
  },
  mobileIntroBlock: {
    backgroundColor: props.bgColor,
    color: props.color,
    display: 'block',
    /* overflow: 'hidden', */
    height: 426,
    position: 'relative',
    '& > img': {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      '&[data-loading]': {
        bottom: -20,
        left: 0
      }
    },

    tabletPortraitAndUp: {
      display: 'none'
    }
  }
}))(({ styles, image }) => {
  if (!image) {
    return null
  }

  const headerProps = useSpring({
    from: {
      transform: 'scale(1)',
      tranformOrigin: 'bottom center'
    },
    delay: 400,
    transform: "scale(1.1)",
    config: config.slow
  })

  return <>
    <section className={styles.introBlock}>
      <animated.div style={headerProps}>
        <IntroBlock header image={image} />
      </animated.div>
    </section>
    <animated.div className={styles.mobileIntroBlock} style={headerProps}>
      <SanityImage {...image} alt={"Join us"} />
    </animated.div>
  </>
})
