import React, { useState } from "react"
import { graphql } from "gatsby"
import { connect as connectFela } from "react-fela"
import { useTransition } from 'react-spring'

import Container from "components/container"
import Layout from "components/layout"
import MoreJobs from "components/MoreJobs"
import { getHexColor } from "theme/colors"
import fluidValues from "utilities/fluidValues"
import calc from "utilities/calc"
import Button from "components/Button"
import { isMobile } from "utilities/tools"
import FullPageScroller from "components/FullPageScroller"
import Hero from "./Hero"
import Header from "./Header"
import IntroImage from "./IntroImage"
import ColorContext from "context/ColorContext"
import MidSection from "./MidSection"

const JoinUsPage = ({ data, rules, styles }) => {
  const {
    title,
    meta,
    slug,
    image,
    introImage,
    blocks,
    color,
    intro,
    getInTouchLink,
    countrySet
  } = data.sanityJoinusPage

  const [t, setT] = useState({ index: 0, direction: 'down'})
  const [parentLock, setParentLock] = useState(false)
  const height = '1200px'
  // const [trans ,setTrans] = useState({
  //   from: {
  //     i: 1,
  //     transform: `translateY(1px)`
  //   },
  //   immediate: false
  // })

  // useLayoutEffect(() => {
  //   console.log(typeof window !== 'undefined')
  //   console.log(isMobile())
  //   setTrans({
  //     from: {
  //       i: (isMobile() ? 1 : 0),
  //       transform: (isMobile() ? `translateY(0)` : `translateY(${t.direction === 'down' ? height : '-' + height})`)
  //     }
  //   })
  // }, [])

  // console.log(trans)

  /* const sectionsProps = useSpring({
   *     from: {
   *         transform: 'scale(1)',
   *         tranformOrigin: 'bottom center'
   *     },
   *     // delay: 400,
   *     transform: inView2 ? "scale(1.1)" : "scale(1)",
   *     config: config.slow
   * })
   */

  const transitions = useTransition(isMobile() ? [...Array(blocks.length).keys()] : t.index, {
    key: isMobile() ? null : t.index,
    from: {
      i: 0,
      transform: `translateY(${t.direction === 'down' ? height : '-' + height})`
    },
    immediate: isMobile() ? true : false,
    enter: { i: 1, transform: `translateY(0px)` },
    leave: { i: 0, transform: `translateY(${t.direction === 'down' ? '-' + height : height})` },
    config: {
      mass: 7,
      tension: 68,
      friction: 49,
      velocity: -0.00001,
      clamp: true
    }
  })

  return (
    <ColorContext.Provider value={color}>
      <Layout
        pageColor={getHexColor(color, '300')}
        linkColor={getHexColor(color, '300')}
        title={title}
        slug={slug}
        headerImage={image}
        meta={meta || {}}
        disableBackUpButton={true}
        settings={data.settings}
        jobs={data.jobs}
        country={countrySet}
      >
        <div id={"step-0"} data-offset={0} />

        <FullPageScroller disable={isMobile()} totalTs={blocks.length} t={t} setT={setT} parentLock={parentLock} setParentLock={setParentLock} >

          <Hero image={image} />
          <Header title={title} intro={intro} country={countrySet}/>
          <IntroImage {...introImage} />

          <div id={"step-2"} data-center={true} />

          {blocks.length > 0 && <section className={styles.blocks}>
            {transitions((props, item) => {
              return <MidSection block={blocks[item]} animProps={props} />
            })}
          </section>}

          <div id={"step-3"} data-offset={-80} />

          {getInTouchLink && <div className={styles.buttonContainer}>
            <Button
              extend={{styles: rules.buttonBottom}}
              to={getInTouchLink}>
              Get in touch
            </Button>
          </div>}

          <Container tag={'section'} onlyLeft extraLeft withContentArea5 extend={{ styles: rules.moreIdeas }}>
            <div id={"step-4"} data-offset={200} />
            <div id={"step-5"} data-offset={400} />
            <MoreJobs hideExcerpt country={countrySet}/>
          </Container>
        </FullPageScroller>

        <div id={"step-6"} />
      </Layout>
    </ColorContext.Provider>
)}

const styles = (props) => ({
  moreIdeas: {
    ...fluidValues({ marginBottom: [40, 120] }),
    paddingTop: 1,
  },
  blocks: {
    overflow: 'hidden',
    backgroundColor: 'transparent',
    boxSizing: 'border-box',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    width: '100%',
    display: 'block',
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: 1440,
    tabletPortraitAndUp: {
      height: '100vh',
      maxHeight: 841
    },

    ...fluidValues({
      marginBottom: [60, 120],
    }),
  },
  buttonContainer: {
    marginBottom: 80,
    '@media(min-width: 1000px)': {
      marginBottom: 0
    }
  },
  buttonBottom: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: calc(170, 380, 320, 1440),
    ipadMiniAndUp: {
    },
    /* '@media(min-width: 1000px)': {
     *   transform: "translateY(-19vw)",
     * },
     * '@media(min-width: 1440px)': {
     *   width: 380,
     *   transform: "translateY(-230px) !important",
     * } */
  },
})

export default connectFela(styles)(JoinUsPage)

export const query = graphql`
query($country: String!) {
    settings: sanitySettings(countrySet: { eq: $country }) {
        ...Settings
    }

    jobs: allSanityJob(filter: { countrySet: { eq: $country } }) {
        edges {
            node {
                ...Jobs
            }
        }
    }

    sanityJoinusPage(countrySet: { eq: $country }) {
      title
      countrySet
      meta {
        title
        description
        other {
          property
          content
        }
      }
      color
      slug {
        current
      }
      intro
      introButton {
        ...sanityLinkFrag
      }
      image {
        ...ImageWithPreview
        asset {
          gatsbyImageData(layout: CONSTRAINED, width:  1440)
        }
      }
      introImage {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width:  1440)
        }
      }
      blocks {
        title,
        _rawContent
        layout
        images {
          asset {
            gatsbyImageData(layout: CONSTRAINED, width:  593)
          }
        }
      }
      getInTouchLink {
        ...sanityLinkFrag
      }
    }
  }
`
