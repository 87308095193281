import React, { useContext } from "react"
import { connect as connectFela } from "react-fela"
import { animated, useSpring, config } from 'react-spring'

import P from "components/typography/P"
import Share from "components/Share"
import fluidValues from "utilities/fluidValues"
import UnderlinedTitle from "components/UnderlinedTitle"
import Button from "components/Button"
import ColorContext from "context/ColorContext"
import { getHexColor } from "theme/colors"

export default connectFela(() => ({
  header: {
    zIndex: 11,
    maxWidth: 1210,
    paddingRight: 20,
    paddingLeft: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'transparent',
    boxSizing: 'border-box',
    position: 'relative',
    width: '100%',
    display: 'block',
    tabletPortraitAndUp: {
      paddingRight: 40,
      paddingLeft: 40,
    },
  },
  headerInner: {
    backgroundColor: getHexColor('Root', '300'),
    marginTop: '-10vh',

    ...fluidValues({
      paddingTop: [32, 120],
      paddingBottom: [32, 120],
      paddingLeft: [22, 145],
      paddingRight: [22, 145],
      // marginTop: [-60, -156],
      marginBottom: [60, 120],
    }),
  },
  introContainer: {
    maxWidth: 514,
    marginLeft: 'auto',
  },
  title: {
    maxWidth: 661
  },
  container: {
    zIndex: 1,
    ...fluidValues({paddingTop: [60, 120]}),
  },
  button: {
    backgroundColor: 'white',
    width: '100%',
    phoneOnly: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  shareTop: {
    ...fluidValues({marginTop: [32, 60]})
  },
}))(({ rules, styles, title, intro, country }) => {
  const color = useContext(ColorContext);
  const introContainerProps = useSpring({
    from: { marginTop: "0vh" },
    marginTop: '-10vh',
    config: config.slow
  })

  return <section className={`${styles.header} section`}>
    <animated.div className={styles.headerInner} style={introContainerProps}>
      <div id={"step-1"} data-offset={-100} />

      <UnderlinedTitle extend={{ styles: rules.title }}>{title}</UnderlinedTitle>

      <div className={styles.introContainer}>
        <P version={'large'}>{intro}</P>

        <Button extend={{ styles: rules.button }} to={'/jobs'}>We’re hiring for these roles</Button>
      </div>

      <Share
        extend={{ styles: rules.shareTop }}
        bgColor={getHexColor(color, '300')}
        url={'/joinus'}
        country={country}
      />
    </animated.div>
  </section>
})
