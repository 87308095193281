import React, { useRef, useState, useEffect } from "react"
import ReactDOMServer from 'react-dom/server';
import { connect as connectFela } from "react-fela"
import Scroll from 'react-scroll'
import * as R from "ramda"

import { useTransition, animated, useSpring } from 'react-spring'
import { isMobile, isTouch } from "utilities/tools"
import { Provider as MenuContextProvider } from "components/MenuContext"
import { reactivateScrollbar, deactivateScrollbar, preventDefault, preventDefaultForScrollKeys, addScrollHandlers, removeScrollHandlers } from "utilities/handlers"
import BackUpButton from "components/BackUpButton"
import ArrowUp from "./svg/ArrowUp"

const hideWhenScrolledPastTheFold = (step, btn) => {
    if (step > 0) {
        btn.style.display = 'block'
    }
}

const resetScroll = (ref, setStep, setT, btn) => {
    ref.style.transform = `translateY(0)`;
    ref.style.transition = `transform 1.5s`;
    setStep(0)
}

const attachBackButton = (ref, backButtonRef, setStep) => {
    const icon = ReactDOMServer.renderToStaticMarkup(<ArrowUp />)
    const link = document.createElement('a')
    link.innerHTML = icon
    link.setAttribute('alt', 'Top of the page')
    link.setAttribute('href', '#')
    link.classList.add('backUpButton')
    link.style.display = 'none'
    backButtonRef.current = link
    link.addEventListener('click', () => resetScroll(ref, setStep, backButtonRef))
    // Catering for bigger touchscreen such as ipads
    link.addEventListener('touchend', () => resetScroll(ref, setStep, backButtonRef))
    ref.parentElement.appendChild(link)
}

const detachBackButton = (backButtonRef) => {
    backButtonRef.remove()
}

const translationPosition = (ref) => R.pipe(
    (ref) => window.getComputedStyle(ref.current),
    (styles) => styles.transform || styles.webkitTransform || styles.mozTransform,
    R.match(/matrix.*\((.+)\)/),
    R.nth(1),
    R.split(", "),
    R.nth(5)
)(ref)

const useScrollDelta = (initialState) => {
    const [touchStart, setTouchStart] = useState(initialState)
    const delta = (event) => {
        switch (event.type) {
        case "WheelEvent":
        case "mousewheel":
        case "wheel":
            return event.deltaY
            break
        case "touchstart":
            return R.pipe(
                R.prop('touches'),
                R.head(),
                R.prop('pageY'),
                setTouchStart
            )(event)
            break
        case "touchend":
            if (touchStart !== 0) {
                const startY = touchStart
                setTouchStart(0)
                return startY - event.changedTouches[0].pageY
            }
            break
        }
    }

    return [delta]
}

const FullPageScroller = ({ rules, styles, t, setT, parentLock, setParentLock, children, disable, totalTs}) => {
    const [scrollLocked, lockScroll] = useState(true)
    const [step, setStep] = useState(0)
    const [touchStart, setTouchStart] = useState(0)
    const [positions, setPositions] = useState([])
    const ref = useRef()
    const backButtonRef = useRef()
    const [getDelta] = useScrollDelta(0)
    const unlocking = () => lockScroll(false)

    useEffect(() => {
        if (disable) {
            return
        }
        Scroll.animateScroll.scrollToTop()
        deactivateScrollbar()

        ref.current = document.querySelector("#gatsby-focus-wrapper")
        attachBackButton(ref.current, backButtonRef, setStep)
        ref.current.style.transform = `translateY(0)`;
        ref.current.style.transition = `transform 1.5s`;
        const steps = typeof window !== 'undefined' && document.querySelectorAll('[id^="step"]')

        setStep(0)
        setPositions(Array.from(steps).map(s => {
            if (s.dataset.center) {
              const midWindow = window.innerHeight/2
              const n = s.nextElementSibling
              const midStep = (n.getBoundingClientRect().bottom - n.getBoundingClientRect().top)/2
              return n.getBoundingClientRect().top - midWindow + midStep
            } else {
            return s.getBoundingClientRect().top + parseInt(s.dataset.offset ?? 0)
            }
        }))
        setTimeout(unlocking, 300)
        return () => {
            detachBackButton(backButtonRef.current)
            ref.current = document.querySelector("#gatsby-focus-wrapper")
            ref.current.style.transform = 'unset';
            ref.current.style.transition = 'unset';
            reactivateScrollbar();
        }
    }, [])

    const scroll = (event) => {
        const delta = getDelta(event)
        if (!delta || (delta < 10 && delta > -10)) {
            return
        }
        event.preventDefault()

        if (scrollLocked || parentLock) {
            return;
        }

        // console.log('step', step, " -> ", step+1);

        if (step === 2) {
            if (delta > 0 && t.index < (totalTs - 1) ) {
                setT(t => ({index: t.index + 1, direction: "down"}))

                lockScroll(true)
                setParentLock(true)
                setTimeout(unlocking, 1000)
                setTimeout(() => setParentLock(false), 1000)
                return
            } else if (delta < 0 && t.index > 0) {
                setT(t => ({index: t.index - 1, direction: "up"}))

                lockScroll(true)
                setParentLock(true)
                setTimeout(unlocking, 1000)
                setTimeout(() => setParentLock(false), 1000)
                return
            }
        }

        if (delta > 0 && step < positions.length - 1) {
            // console.log('step', step, " -> ", step+1);

            const pos = positions[step + 1]
            ref.current.style.transform = `translateY(-${pos}px)`;
            // console.log('down', pos);

            hideWhenScrolledPastTheFold(step + 1, backButtonRef.current)
            setStep((step) => step + 1 )
        } else if (delta < 0 && step >= 1 && step < positions.length) {
            // console.log('step', step, " -> ", step-1);

            const pos = positions[step - 1]
            ref.current.style.transform = `translateY(-${pos}px)`;
            // console.log('up', pos);
            hideWhenScrolledPastTheFold(step - 1, backButtonRef.current)
            setStep((step) => step - 1 )
        } else if (delta > 0 && step >= positions.length - 2) {
            // console.log('down to bottom');

            const height = ref.current.offsetHeight
            const windowHeight = window.innerHeight
            const y = translationPosition(ref)
            const rest = height - windowHeight - Math.abs(y)

            if (rest < windowHeight) {
                ref.current.style.transform = `translateY(calc(-${height}px + 100vh))`;
            } else {
                ref.current.style.transform = `translateY(-${Math.abs(y) + windowHeight}px)`;
            }
            setStep((step) => step + 1 )
        } else if (delta < 0 && step >= positions.length - 2) {
            // console.log('up to last step');

            const lastStep = R.last(positions)
            const windowHeight = window.innerHeight

            const y = translationPosition(ref)
            // console.log('laststep', lastStep, 'y', y);
            const rest = Math.abs(lastStep) - Math.abs(y)

            if (Math.abs(rest) < windowHeight) {
                ref.current.style.transform = `translateY(-${lastStep}px)`;
                // console.log('step');
            } else {
                ref.current.style.transform = `translateY(-${Math.abs(y) - windowHeight}px)`;
                // console.log('wheight');

            }
            setStep((step) => step - 1 )
        }

        lockScroll(true)
        setTimeout(unlocking, 1000)
    }

    useEffect(() => {
        if (disable) {
            return
        }
        addScrollHandlers(scroll)
        return () => {
            removeScrollHandlers(scroll)
        }
    })

    return <div className={styles.fullPageContainer}>
               {children}
           </div>
}

const styles = (props) => ({
    fullPageContainer: {
        '& > section:first-of-type': {
            width: '100vw',
            height: '100vh',
            maxHeight: 'unset'
        },
        '& > section:first-of-type > *': {
            width: '100vw',
            height: '100vh',
            maxHeight: 'unset'
        },
        '& > section:first-of-type > * > *': {
            width: '100%',
            height: '100%',
            maxHeight: 'unset'
        }
    },
})

export default connectFela(styles)(FullPageScroller)
