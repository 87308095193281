import React from "react"
import { connect as connectFela } from "react-fela"
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import BlockContent from '@sanity/block-content-to-react'
import { animated } from 'react-spring'

import MidSectionMobileSpring from './MidSectionMobileSpring'
import Ul from "components/typography/UL"
import P from "components/typography/P"
import colors from "theme/colors"
import fluidValues from "utilities/fluidValues"
import calc from "utilities/calc"
import fluidFonts from "utilities/fluidFonts"
import { commonMarks } from "utilities/serializers"
import { serializers as linkSerializers } from "components/BaseLink.js"
import { fontFamilySerif } from "gatsby-theme-fela/fonts"

/* Serializers */
const serializers = ({styles, rules}) => {
  return {
    list: props => ( <Ul {...props} type={props.type} extend={{styles: rules.list}}>{props.children}</Ul> ),
    listItem: props => {
      return <P tag={'li'} extend={{styles: rules.list}} version={props.node?.listItem === 'dash' ? "list-light" : "list"}>{props.children}</P>
    },
    types: {
      block: (props) => {
        return <P version='body' extend={{styles: rules.normal}}>{props.children}</P>
      },
    },
    marks: {
      ...linkSerializers(styles, rules),
      ...commonMarks({styles, rules}),
    }
  }
}

/* 0.3 => 0       => 0
 * 0.4 => 0.1/0.5
 * 0.5 => 0.2/0.5
 * 0.6 => 0.3/0.5
 * 0.7 => 0.4/0.5
 * 0.8 => 0.5/0.5 => 1
 * 0.9 => 0.6
 * 1   => 0.7 */
const opacity = (i) => {
  if (i > 0.4) {
    return (i - 0.4) / 0.6
  } else {
    return 0
  }
}

export default connectFela((props) => ({
  blocksContainers: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',

    tabletPortraitAndUp: {
      position: 'absolute',
      height: '100vh',
      maxHeight: 841,
      top: 0,
      left: 0,
      // '& > div:first-child': {
      //   '& > div:first-child': {
      //     transform: 'translateY(max(100vh, 841px))'
      //   },
      //   '& > div:last-child': {
      //     opacity: 0
      //   }
      // }
    },
  },
  blocksInner: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 1285,
    alignItems: 'center',
    position: 'relative',
    paddingLeft: 20,
    marginTop: 50,
    marginBottom: 50,

    ...fluidValues({
      paddingRight: [20, 40],
    }),

    tabletPortraitAndUp: {
      flexWrap: 'nowrap',
      gap: 62,
      paddingLeft: 0,
      marginTop: 'auto !important',
      marginBottom: 'auto !important',
    },
    tabletLandscapeAndUp: {
      gap: 82,
    },
    maxAndAbove: {
      gap: 139,
    }
  },
  blockInner_1: {
    height: calc(592, 398, 320, 768),
    tabletPortraitAndUp: {
      height: calc(398, 692, 768, 1440),
      justifyContent: 'start',
    },
    '@media(min-width: 1440px)': {
      height: 692
    },
    '& > div:first-child': {
      phoneOnly: {
        height: 281
      }
    }
  },
  blockInner_2: {
    height: calc(576, 385, 320, 768),
    tabletPortraitAndUp: {
      height: calc(385, 635, 768, 1440),
      justifyContent: 'start',
    },
    '@media(min-width: 1440px)': {
      height: 635
    },
    '& > div:first-child': {
      phoneOnly: {
        height: 311
      }
    }
  },
  blockInner_3: {
    height: calc(570, 519, 320, 768),
    tabletPortraitAndUp: {
      height: calc(519, 841, 768, 1440),
      justifyContent: 'start',
    },
    '@media(min-width: 1440px)': {
      height: 841
    },
    maxAndAbove: {
      marginBottom: 200,
    },
    '& > div:first-child': {
      phoneOnly: {
        height: 304
      }
    }
  },
  blockInner_4: {
    height: calc(462, 369, 320, 768),
    tabletPortraitAndUp: {
      height: calc(369, 390, 768, 1440),
      justifyContent: 'start',
    },
    '@media(min-width: 1440px)': {
      height: 390
    },
    maxAndAbove: {
      marginTop: 200,
      marginBottom: 200,
    },
    '& > div:first-child': {
      phoneOnly: {
        height: 180
      }
    }
  },
  blockInner_5: {
    height: calc(466, 353, 320, 768),
    tabletPortraitAndUp: {
      height: calc(353, 427, 768, 1440),
      justifyContent: 'start',
    },
    '@media(min-width: 1440px)': {
      height: 427
    },
    '& > div:first-child': {
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    maxAndAbove: {
      marginTop: 200,
      marginBottom: 200,
    },
    '& > div:first-child': {
      phoneOnly: {
        height: 195
      }
    }
  },
  blockInner_6: {
    height: calc(597, 448, 320, 768),
    tabletPortraitAndUp: {
      height: calc(448, 771, 768, 1440),
      justifyContent: 'start',
    },
    '@media(min-width: 1440px)': {
      height: 771
    },
    '& > div:first-child': {
      phoneOnly: {
        height: 311
      }
    }
  },
  blockText: {
    backgroundColor: 'white',
    flex: '1 1 100%',

    tabletPortraitAndUp: {
      flex: '1 1 43.2%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
    }
  },
  blockImages: {
    marginBottom: 32,
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    height: '50%',
    width: '100%',
    maxWidth: 280,
    marginLeft: 'auto',
    marginRight: 'auto',

    tabletPortraitAndUp: {
      maxWidth: 'unset',
      flex: '1 1 50%',
      height: '100%',
      marginBottom: 0,
      justifyContent: 'start',
    }
  },
  1: {
    '&:nth-child(1)': {
      order: 2,
      zIndex: 2,
      position: 'absolute !important',
      top: 0,
      right: 0,
      width: calc(163, 190, 320, 768),
      height: calc(226, 251, 320, 768),
      ipadMiniAndUp: {
        order: 0,
        top: 'unset',
        right: 'unset',
        bottom: 0,
        left: 0,
        width: calc(190, 362, 768, 1440),
        height: calc(251, 486, 768, 1440),
      },
      '@media(min-width: 1440px)': {
        width: '362px !important',
        height: '486px !important'
      }
    },
    '&:nth-child(2)': {
      bottom: 0,
      left: 0,
      width: calc(141, 177, 320, 768),
      height: calc(197, 245, 320, 768),
      position: 'absolute !important',
      ipadMiniAndUp: {
        top: 0,
        right: 0,
        left: 'unset',
        bottom: 'unset',
        zIndex: 2,
        width: calc(177, 322, 768, 1440),
        height: calc(245, 467, 768, 1440),
      },
      '@media(min-width: 1440px)': {
        width: '322px !important',
        height: '467px !important'
      }
    }
  },
  2: {
    '&:nth-child(1)': {
      zIndex: 2,
      position: 'absolute !important',
      left: 0,
      bottom: 0,
      width: calc(139, 144, 320, 768),
      height: calc(199, 206, 320, 768),
      ipadMiniAndUp: {
        left: calc(74, 130, 768, 1440),
        width: calc(144, 232, 768, 1440),
        height: calc(206, 337, 768, 1440),
      },
      '@media(min-width: 1440px)': {
        width: '232px !important',
        height: '337px !important'
      }
    },
    '&:nth-child(2)': {
      position: 'absolute !important',
      width: calc(164, 178, 320, 768),
      height: calc(251, 273, 320, 768),
      top: 0,
      right: 0,
      ipadMiniAndUp: {
        width: calc(178, 322, 768, 1440),
        height: calc(273, 493, 768, 1440),
      },
      '@media(min-width: 1440px)': {
        width: '322px !important',
        height: '493px !important'
      }
    },
  },
  3: {
    '&:nth-child(1)': {
      position: 'absolute !important',
      zIndex: 2,
      bottom: 28,
      left: 0,
      width: calc(120, 159, 320, 768),
      height: calc(164, 216, 320, 768),
      ipadMiniAndUp: {
        order: 0,
        bottom: calc(56, 77, 768, 1440),
        width: calc(159, 271, 768, 1440),
        height: calc(216, 370, 768, 1440),
      },
      '@media(min-width: 1440px)': {
        width: '271px !important',
        height: '370px !important'
      }
    },
    '&:nth-child(2)': {
      position: 'absolute !important',
      width: calc(206, 263, 320, 768),
      height: calc(156, 198, 320, 768),
      right: -20,
      top:0,
      ipadMiniAndUp: {
        right: 0,
        width: calc(263, 464, 768, 1440),
        height: calc(198, 346, 768, 1440),
      },
      '@media(min-width: 1440px)': {
        width: '464px !important',
        height: '346px !important'
      }
    },
    '&:nth-child(3)': {
      position: 'absolute !important',
      width: calc(92, 119, 320, 768),
      height: calc(127, 165, 320, 768),
      right: 40,
      bottom: 0,
      ipadMiniAndUp: {
        right: 0,
        width: calc(119, 207, 768, 1440),
        height: calc(165, 283, 768, 1440),
      },
      '@media(min-width: 1440px)': {
        width: '207px !important',
        height: '283px !important'
      }
    },
  },
  4: {
    '&:nth-child(1)': {
      marginTop: 'auto',
      width: 'calc(102% - 20px)',
      left: -40,
      tabletPortraitAndUp: {
        marginBottom: 'auto',
        left: 'unset',
        width: '100%',
      },
      '@media(min-width: 1440px)': {
        width: 593,
        height: 385
      }
    },
  },
  5: {
    '&:nth-child(1)': {
      position: 'absolute !important',
      top: 0,
      left: 0,
      width: calc(85, 90, 320, 768),
      height: calc(147, 160, 320, 768),
      ipadMiniAndUp: {
        left: 40,
        width: calc(90, 169, 768, 1440),
        height: calc(160, 291, 768, 1440),
      },
      '@media(min-width: 1440px)': {
        width: '169px !important',
        height: '291px !important'
      }
    },
    '&:nth-child(2)': {
      /* position: 'absolute !important', */
      width: calc(85, 90, 320, 768),
      height: calc(147, 160, 320, 768),
      top: 0,
      bottom: 0,
      marginTop: 'auto',
      marginBottom: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
      left: 0,
      position: 'relative',
      ipadMiniAndUp: {
        left: 20,
        width: calc(90, 169, 768, 1440),
        height: calc(160, 291, 768, 1440),
      },
      '@media(min-width: 1440px)': {
        width: '169px !important',
        height: '291px !important'
      }
    },
    '&:nth-child(3)': {
      position: 'absolute !important',
      bottom: 0,
      right: 0,
      width: calc(85, 90, 320, 768),
      height: calc(147, 160, 320, 768),
      ipadMiniAndUp: {
        width: calc(90, 169, 768, 1440),
        height: calc(160, 291, 768, 1440),
      },
      '@media(min-width: 1440px)': {
        width: '169px !important',
        height: '291px !important'
      }
    },
  },
  6: {
    '&:nth-child(1)': {
      order: 2,
      zIndex: 2,
      position: 'absolute !important',
      top: 0,
      right: 0,
      width: calc(139, 176, 320, 768),
      height: calc(199, 258, 320, 768),
      ipadMiniAndUp: {
        order: 0,
        top: 'unset',
        right: 'unset',
        bottom: 0,
        left: 40,
        width: calc(176, 322, 768, 1440),
        height: calc(258, 473, 768, 1440),
      },
      '@media(min-width: 1440px)': {
        width: '322px !important',
        height: '473px !important'
      }
    },
    '&:nth-child(2)': {
      bottom: 0,
      left: 0,
      width: calc(164, 175, 320, 768),
      height: calc(251, 268, 320, 768),
      position: 'absolute !important',
      ipadMiniAndUp: {
        top: 0,
        right: 0,
        left: 'unset',
        bottom: 'unset',
        width: calc(175, 322, 768, 1440),
        height: calc(268, 493, 768, 1440),
      },
      '@media(min-width: 1440px)': {
        width: '322px !important',
        height: '493px !important'
      }
    }
  },

  blockContent: {
    onAfter: {
      backgroundColor: (props.light ? '#FFFFFF' : colors.colorConsole500),
      content: '""',
      display: 'block',
      height: 6,
      minWidth: 24,
      maxWidth: 37,
      width: '7%',
      position: 'relative',
      zIndex: 1,
      ...fluidValues({ marginTop: [12, 35] }),
    }
  },
  list: {
    fontWeight: 400
  },
  blockTitle: {
    marginTop: 0,
    whiteSpace: 'pre-wrap',
    fontFamily: fontFamilySerif,
    fontWeight: 300,
    letterSpacing: 0,
    ...fluidFonts({fontSize: [20, 52], lineHeight: [26, 64]}),
    extend: {
      ...fluidValues({marginBottom: [32, 40]})
    }
  },
}))(({ rules, styles, block, key, animProps }) => {
  const layoutStyles = styles["blockInner_" + block.layout]
  return <MidSectionMobileSpring className={styles.blocksContainers}>
    <div className={`${ styles.blocksInner } ${ layoutStyles }`}>

      <animated.div className={styles.blockImages} style={animProps}>
        {block.images.length > 0 && block.images.map((image, i) => {
          return <animated.div className={styles[block.layout]} key={"image_" + i}>
            <Img image={image.asset?.gatsbyImageData} key={i + "image"} alt={""} />
          </animated.div>
        })}
      </animated.div>

      <animated.div className={styles.blockText} style={{opacity: animProps.i?.interpolate(opacity)}}>
        <p className={styles.blockTitle}>{block.title}</p>

        {block._rawContent &&
         <BlockContent renderContainerOnSingleChild className={styles.blockContent} blocks={block._rawContent} serializers={serializers({rules, styles})} />}
      </animated.div>

    </div>
  </MidSectionMobileSpring>
})


// const imageSprings = block.images.map((image) => {
//     return useSpring({
//         from: { opacity: inView && animCount == 1 ? 0 : 1 },
//         opacity: 1,
//         immediate: inView && animCount < 1,
//         reset: inView && animCount == 1,
//     })
// })

// style={imageSprings[i]}
