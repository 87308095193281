import React from "react"
import { connect as connectFela } from "react-fela"
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { useInView } from 'react-intersection-observer';

import fluidValues from "utilities/fluidValues"

export default connectFela(() => ({
  container: {
    zIndex: 11,
    maxWidth: 1210,
    paddingRight: 20,
    paddingLeft: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'transparent',
    boxSizing: 'border-box',
    position: 'relative',
    width: '100%',
    display: 'block',
    tabletPortraitAndUp: {
      paddingRight: 40,
      paddingLeft: 40,
    },
  },
  image: {
    ipadMiniAndDown: {
      maxWidth: 571,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    maxWidth: 780,
    ...fluidValues({
      marginBottom: [60, 120],
    })
  },
  anim: {
    overflow: 'hidden',
    '& img': {
      transform: "scale(1.1) !important",
      transformOrigin: 'bottom center',
      transition: 'transform 1.3s !important'
    }
  },
}))(({ styles, asset, credits }) => {
  if (!asset) {
    return null
  }
  const [ref, inView,] = useInView()

  return <section className={styles.container} ref={ref}>
    <Img
      credits={credits}
      className={`${styles.image} ${inView ? styles.anim : ''}`}
      image={asset.gatsbyImageData}
      alt={ "" } />
  </section>
})
